import {HTTPError} from "ky";
import isStaff from '../../services/isStaff';
import { localStorageClearNot } from "../../services/utils";

const http422UnprocessableEntity = (err) => {
    const {response, request, options} = err;
    const our = {
        status: response.status,
        json:() => {
            return new Promise((resolve, reject) => {
                response.clone().json().then(data => {
                    data._errors = JSON.parse(JSON.stringify(data.errors));
                    const params = Object.entries(data.errors).map(([key, value]) => {
                        return [
                            key,
                            Object.fromEntries(value.map(({code, parameters}) => [code, parameters]))
                        ];
                    });
                    data.params = Object.fromEntries(params);
                    const errors = Object.entries(data.errors).map(([key, value]) => {
                        return [
                            key,
                            value.map(({code}) => (code))
                        ]
                    });
                    data.errors = Object.fromEntries(errors);
                    resolve(data);
                });
            });
        }
    };
    throw (new HTTPError(our, request, options));
};

const catcher = (handlers, err) => {
    const status = err?.response?.status;
    if (handlers['before']) {
        handlers['before'].call(null, err);
    }
    if (status && handlers[status]) {
        handlers[status].call(null, err);
    }
    throw err;
};

const cs = (err) => {
    return catcher(
        {
            401: (err) => {
                localStorageClearNot(['afterLogin', 'promtState', 'accessToken', 'expiredAt', 'member']);
                window.location = `/staff`;
            },
            503: (err) => {
                window.location = `/staff`;
            },
            422: http422UnprocessableEntity,
        },
        err
    );
}

const cm = (err) => {
    return catcher(
        {
            before: (err) => {
                const staff = window.location.hash.indexOf('super') !== -1;
                if (staff) {
                    window.location = '/';
                }
            },
            401: (err) => {
                const isLoader = window.location.pathname.indexOf('loader') !== -1;
                const staff = window.location.hash.indexOf('super') !== -1;
                if (!isLoader && !staff) {
                    localStorageClearNot(['afterLogin', 'promtState', 'staffAccessToken', 'staffExpiredAt', 'staffMember']);
                    let lang = window.location.href.match(/(en|cn|tc|jp|kr|ru)/)[0];
                    window.location = `/${lang}/login`;
                }
            },
            503: (err) => {
                window.location = '/';
            },
            422: http422UnprocessableEntity,
        },
        err
    );
};

const makeCatchHandler = () => {
    if (isStaff()) {
        return cs;
    } else {
        return cm;
    }
}

export default makeCatchHandler;
