import { useEffect, useState } from "react";
import {connect} from "react-redux";
import { Popover, Overlay, Button  } from "react-bootstrap";
import {injectIntl} from "react-intl";
import { skipAllPromt} from "../actions";


const PromtsManagment = ({ promt, children, mobile, intl, skipAllPromt }) => {

    const {formatMessage} = intl;
    const [showPromt, setShowPromt] = useState(false);
    const [params, setPatams] = useState(null);

    useEffect(() => {
        let root = document.getElementById("root");
        if(!promt?.complitedPromt.skipAll && promt.currentPromt) {
            root.classList.add('promt');
            const items = promt.elementsList.get(promt.currentPromt);
            if (items && items.length) {
                items.forEach(item => {
                    if (item.element)
                        item.element.classList.add('promt-active-element');
                })
            }

            let params = promt.elementsList.get(promt.currentPromt);
            params = params && params.length ? params[0] : null;
            setPatams(params);
            setShowPromt(params && params.element);
        } else {
            root.classList.remove('promt');
            promt.elementsList.forEach((items) => {
                if (items && items.length) {
                    items.forEach(item => {
                        if (item.element)
                            item.element.classList.remove('promt-active-element');
                    })
                }
            })

            setPatams(null);
            setShowPromt(false);
        }

    }, [promt]);

    return <>
        {children}
        {!promt?.complitedPromt.skipAll && promt.currentPromt && <div className={"promt-wrap " + (mobile ? "promt-wrap__mobile":"")} onClick={() => params && params.nextCallback && params.nextCallback()}>
            {showPromt &&
                <Overlay
                    show={showPromt}
                    
                    onEntered={e => e.style.transform = (mobile && e.style.transform) ?  e.style.transform.replace(/\([0-9]*/, '(0') : e.style.transform}
                    className={mobile ? "mobileMenu" : ""}
                    placement="bottom"
                    target={params.element}
                >
                    <Popover  className={"promt-popover "}>
                        <Popover.Header >{params.header ?? ""}</Popover.Header>
                        <Popover.Body>
                            {params.body ?? ""}
                        </Popover.Body>
                        <div className="d-flex w-100">
                            <Button variant="outline-info" className="flex-1 me-2" onClick={ e => {e.stopPropagation(); skipAllPromt()}}>{formatMessage({id:"COMMON.SKIP"})}</Button>
                            <Button variant="info" className="flex-1 ms-2" onClick={() => params.nextCallback && params.nextCallback()}>{formatMessage({id:"COMMON.NEXT"})}</Button>
                        </div>
                    </Popover>
                </Overlay>
            }
        </div>}
    </>
}

export default connect(
    (state) => ({
        promt: state.promt,
        mobile: state.common.mobile,
    }),
    (dispatch) => ({
        skipAllPromt: (state) => {
            dispatch(skipAllPromt(state));
        },
    }),
  )(injectIntl(PromtsManagment));
