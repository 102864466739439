import {injectIntl} from "react-intl";
import { connect } from "react-redux";
import { Navbar, Container, Nav, NavDropdown, Dropdown  } from "react-bootstrap";
import LanguageMenu from "../../../components/LanguageMenu";
import {useEffect, useState, useMemo, useRef} from "react";
import {useParams, useNavigate, useLocation} from "react-router-dom";
import loginService from '../../../api/login';
import BalanceItem from "./BalanceItem";
import LinkedWalletMenuItem from "./LinkedWalletMenuItem";
import {setGoldrushMainstate, setLyState, setMarketplaceDefault, addPromtElement, activatePromt, complitePromt, setMarketplaceContentType} from "../../../actions";
import { ticketFormat } from "../../../utils/numberFormat";
import HeaderFullMenu from './HeaderFullMenu';
import HeaderShortMenu from './HeaderShortMenu';
import BalanceSubmenu from './HeaderBalanceSubmenu';
import MobileMenuSection from './MobileMenuSection';
import MobileBalance from "./MobileBalance";
import ModalConfirm from "./ModalConfirm";
import ModalSysWallet  from "./ModalSysWallet";
import ModalSysWalletToAbey  from "./ModalSysWalletToAbey";
import ModalTickets  from "./ModalTickets";
import ModalComplitePromt  from "./ModalComplitePromt";
import MobileModalAWBalances from "./MobileModalAWBalances";
import { menuLang } from '../../../services/language';
import { localStorageClearNot } from "../../../services/utils";
import ResourceList from '../../marketplace/ResourceList';
import cn from "classnames";
import { BASE_DOMAIN } from "etc/define";

import logo from '../../../assets/vlands_logo.svg';
import AW937HideElementsForCondition from "../../../components/crutches/AW937HideElementsForCondition";

import {checkCategory3DVisibility, check3DAssetsVisibility} from "utils/checkCategory3DVisibility";

const Header = ({
        member,
        intl,
        mobile,
        memberHasPlots,
        promt,
        myWood,
        myMinerals,
        setGoldrushMainstate,
        setLumberYardMainstate,
        setMarketplaceDefault,
        isShortMenu,
        activatePromt,
        complitePromt,
        addPromtElement,
        setMarketplaceContentType,
        _2DAccess,
        founderPlanIsVisible,
        isAllowedVB
}) => {
    const {formatMessage} = intl;
    let navigate = useNavigate();
    let location = useLocation();

    const awRef = useRef();
    const sysRef = useRef();
    const ticketRef = useRef();
    const mobileMenuRef = useRef();

    const { lang } = useParams();
    const postfix = window.location.pathname.replace(`/${lang}/`, '');


    const onClickLogout = () => {
        loginService.logout().then(() => {
            localStorageClearNot(['afterLogin', 'promtState', 'staffAccessToken', 'staffMember', 'staffExpiredAt']);
            window.location = `/${lang}`;
        });
    };

    const [show, setShow] = useState(false);
    const [showSysWallet, setShowSysWallet] = useState(false);
    const [showTickets, setShowTickets] = useState(false);
    const [showCompltePromts, setShowCompltePromts] = useState(promt.complitedPromt.mobileTicket || promt.complitedPromt.ticket);
    const [showSysWalletToAbey, setShowSysWalletToAbey] = useState(false);
    const [showMobileAWBalancesModal, setShowMobileAWBalancesModal] = useState(false);

    const handleClose = () => {
        setShow(false);
    };
    const handleShow = () => {
        setShow(true);
        setMobileMenuShow(false);
        setMobileMemberMenuShow(false);
    };
    const [mobileMenuShow, setMobileMenuShow] = useState(false);
    const [mobileMemberMenuShow, setMobileMemberMenuShow] = useState(false);

    useEffect(() => {
        setMobileMenuShow(false);
        setMobileMemberMenuShow(false);
    }, [location]);


    useEffect(() => {
        let timerId;
        if(member && !promt.currentPromt) {
            timerId = setTimeout(() => mobile
                ? activatePromt('mobileMenu')
                : activatePromt('sys')
            , 200)

        }
        return () => {
            if (timerId) clearTimeout(timerId);
        }
    }, [member, mobile]);

    useEffect(() => {
        if (mobile) {
            addPromtElement({
                element: mobileMenuRef.current,
                promtName: 'mobileMenu',
                header: formatMessage({id: "AW458.MAIN_MENU"}),
                body: formatMessage({id: "PROMT.MAIN_MENU_DESC"}),
                nextCallback: ()=> {
                    setMobileMenuShow(true);
                    complitePromt('mobileMenu');
                    activatePromt('mobileSys');
                }
            });
        } else {
            addPromtElement({
                element: awRef.current,
                promtName: 'aw',
                header: formatMessage({id: "AW458.ABEY_BALANCE"}),
                body: formatMessage({id: "PROMT.ABEY_DESC"}),
                nextCallback: ()=> {
                    complitePromt('aw');
                    setShowSysWalletToAbey(true);
                }
            });

            addPromtElement({
                element: sysRef.current,
                promtName: 'sys',
                header: formatMessage({id: "AW458.SYSTEM_WALLET"}),
                body: formatMessage({id: "PROMT.SYSTEM_WALLET_DESC"}),
                nextCallback: ()=> {
                    complitePromt('sys');
                    setShowSysWallet(true);
                }
            });
            addPromtElement({
                element: ticketRef.current,
                promtName: 'ticket',
                header: formatMessage({id: "AW458.PLOT_TICKET_BALANCE"}),
                body: formatMessage({id: "PROMT.TICKET_DESC"}),
                nextCallback: ()=> {
                    complitePromt('ticket');
                    setShowTickets(true);
                }
            });
        }
    }, [mobile]);


    const yesNetCallback = () => {
        loginService.yesnoLink()
            .then(response => response.json())
            .then((data) => {
                window.open(data.loginUrl ?? data.url ?? "", '_blank').focus();
            })

    }

    const isActiveMarketplace = (type) => {
        return [`marketplace`].indexOf(postfix) !== -1 && localStorage.getItem('marketplace-type') === type;
    }

    const [myMaterials, setMyMaterials] = useState(null);
    useEffect(() => {
        if (myWood && myMinerals)
            setMyMaterials([...myMinerals, ...myWood])
        else
            setMyMaterials(null)
    }, [myWood, myMinerals]);

    const isCategory3DVisible = checkCategory3DVisibility(member.id);
    const isCategory3DAssetsVisible = check3DAssetsVisibility(member.id);

    // AW-937
    const AW937_HIDE_ELEMENTS_FOR = useMemo(() => process.env?.REACT_APP_HIDE_FOR?.split(',') ?? [], []);
    const AW937_IS_HIDDEN = useMemo(() => AW937_HIDE_ELEMENTS_FOR.includes(member.id.toString()), [member.id, AW937_HIDE_ELEMENTS_FOR]);

    const Mobile = <>
    <Navbar className="main-header w-100" bg="white" variant="light">
            <Container fluid={true} className="px-3">
                <Navbar.Brand href={[`/${lang}`, `/${lang}/`, ''].indexOf(postfix) !== -1 ? null: `/${lang}`} className={"py-0"}>
                    <img alt="" src={logo} style={{height: "32px"}}></img>
                    <h1>{formatMessage({id:"HEADER.VLANDS"})}</h1>
                </Navbar.Brand>
                <Navbar.Collapse>
                    <Nav className={"w-100 align-items-center justify-content-end header--right-nav"}>
                        <Nav.Item className="text-accent d-flex  align-items-center"
                            onClick={() => {
                                if (!mobileMemberMenuShow) {
                                    complitePromt('mobileMenu');
                                    activatePromt('mobileSys');
                                }
                                setMobileMenuShow(false);
                                setMobileMemberMenuShow(!mobileMemberMenuShow);
                            }}
                            disabled={!promt.complitedPromt.skipAll && promt.currentPromt} ref={mobileMenuRef}
                        >

                            <strong>
                                <span >{formatMessage({id:'COMMON.ID'})}: </span>
                                {member.id}
                            </strong>
                            <i style={{fontSize: "9px"}} className={mobileMemberMenuShow ? "ms-2 faw-vector_up" : "ms-1 faw-vector_down"}></i>
                        </Nav.Item>
                        <NavDropdown.Divider className={"m-0"} />

                        <button className={"mobile-header-menu" + (mobileMenuShow ? ' active' : '')} onClick={() => {
                                setMobileMenuShow(!mobileMenuShow);
                                setMobileMemberMenuShow(false);
                            }}
                        >
                            <i className={mobileMenuShow ? "faw-cross" : "faw-burger"} style={{'fontSize':'0.938rem'}} />
                        </button>

                    </Nav>
                </Navbar.Collapse>
            </Container>
        </Navbar>

        {mobileMemberMenuShow && <div className="mobile-menu owner-menu">
            <MobileBalance {...{setMobileMenuShow, setShowSysWallet, setShowSysWalletToAbey, setShowTickets, setShowMobileAWBalancesModal}} />

            <div className="mobile-balance w-100">
                <div className="mobile-balance-label mb-2">{formatMessage({id:'COMMON.RESOURCES'})}:</div>
                <ResourceList materials={myMaterials ?? []} />
            </div>
        </div>}

        {mobileMenuShow && <div className="mobile-menu">

            <MobileMenuSection
                {...{
                    sectionClass: '',
                    label: formatMessage({id:'HEADER.MAP'}),
                    onLabelClick: () => { navigate(`/${lang}`) },
                    activeRoutes: ['']
                }}
            />
            <AW937HideElementsForCondition hide={!memberHasPlots}>
                <MobileMenuSection
                    {...{
                        label: formatMessage({id:'LINK.MARKETPLACE'}),

                        activeRoutes: ['marketplace',  'marketplace/exchangeupgradehistory']
                    }}
                >
                    <a className={'mobile-menu--label '}  href={`#`}><strong>{formatMessage({id:'MARKETPLACE.MARKET'})}</strong> </a>
                    <a onClick={() => { navigate(`/${lang}/marketplace`); setMarketplaceContentType('buy-vehicles'); }} className={cn('mobile-menu--item', {'active': isActiveMarketplace('buy-vehicles')})} >{formatMessage({id:'MARKETPLACE.3D'})}</a>
                    {isCategory3DVisible && <a onClick={() => { navigate(`/${lang}/marketplace`); setMarketplaceContentType('buy-premium-buildings'); }} className={cn('mobile-menu--item', {'active': isActiveMarketplace('buy-premium-buildings')})} >{formatMessage({id:'ASSET.PREMIUM_BUILDING'})}</a>}
                    <a onClick={() => { navigate(`/${lang}/marketplace`); setMarketplaceContentType('buy-buildings'); }} className={cn('mobile-menu--item', {'active': isActiveMarketplace('buy-buildings')})} >{formatMessage({id:'MARKETPLACE.BUILDINGS'})}</a>

                    {isCategory3DAssetsVisible && <a onClick={() => { navigate(`/${lang}/marketplace`); setMarketplaceContentType('buy-avatars'); }}      className={cn('mobile-menu--item', {'active': isActiveMarketplace('buy-avatars')})}      >{formatMessage({id:'MARKETPLACE.AVATARS'})}</a>}
                    {isCategory3DAssetsVisible && <a onClick={() => { navigate(`/${lang}/marketplace`); setMarketplaceContentType('buy-furniture'); }}    className={cn('mobile-menu--item', {'active': isActiveMarketplace('buy-furniture')})}    >{formatMessage({id:'MARKETPLACE.FURNITURES'})}</a>}
                    {isCategory3DAssetsVisible && <a onClick={() => { navigate(`/${lang}/marketplace`); setMarketplaceContentType('buy-animation'); }}    className={cn('mobile-menu--item', {'active': isActiveMarketplace('buy-animation')})}    >{formatMessage({id:'MARKETPLACE.ANIMATION'})}</a>}
                    {isCategory3DAssetsVisible && <a onClick={() => { navigate(`/${lang}/marketplace`); setMarketplaceContentType('buy-plants_trees'); }} className={cn('mobile-menu--item', {'active': isActiveMarketplace('buy-plants_trees')})} >{formatMessage({id:'MARKETPLACE.PLANTS_TREES'})}</a>}
                    {isCategory3DAssetsVisible && <a onClick={() => { navigate(`/${lang}/marketplace`); setMarketplaceContentType('buy-pets'); }}         className={cn('mobile-menu--item', {'active': isActiveMarketplace('buy-pets')})}         >{formatMessage({id:'MARKETPLACE.PETS'})}</a>}

                    {/* {(!isProd || member.accessLive2Earn) && <a onClick={() => { navigate(`/${lang}/marketplace`); setMarketplaceContentType('buy-activity'); }} className={cn('mobile-menu--item', {'active': isActiveMarketplace('buy-activity')})}>{formatMessage({id:'ACTIVITY.TRACKERS'})}</a>} */}
                    <a className={'mobile-menu--label  '}  href={`#`}><strong>{formatMessage({id:'MARKETPLACE.ASSETS'})}</strong> </a>
                    
                    <a onClick={() => { navigate(`/${lang}/marketplace`); setMarketplaceContentType('sell-vehicles'); }} className={cn('mobile-menu--item', {'active': isActiveMarketplace('sell-vehicles')})} >{formatMessage({id:'MARKETPLACE.3D'})}</a>
                    {isCategory3DVisible && <a onClick={() => { navigate(`/${lang}/marketplace`); setMarketplaceContentType('sell-premium-buildings'); }} className={cn('mobile-menu--item', {'active': isActiveMarketplace('sell-premium-buildings')})} >{formatMessage({id:'ASSET.PREMIUM_BUILDING'})}</a>}
                    <a onClick={() => { navigate(`/${lang}/marketplace`); setMarketplaceContentType('sell-buildings'); }} className={cn('mobile-menu--item', {'active': isActiveMarketplace('sell-buildings')})} >{formatMessage({id:'MARKETPLACE.BUILDINGS'})}</a>
                    {isAllowedVB && <a onClick={() => { navigate(`/${lang}/marketplace`); setMarketplaceContentType('sell-virtualbuildings'); }} className={cn('mobile-menu--item', {'active': isActiveMarketplace('sell-virtualbuildings')})} >{formatMessage({id:'MARKETPLACE.VIRTUAL_BUILDINGS'})}</a>}

                    <a onClick={() => { navigate(`/${lang}/marketplace`); setMarketplaceContentType('sell-minerals'); }} className={cn('mobile-menu--item', {'active': isActiveMarketplace('sell-minerals')})} >{formatMessage({id:'MARKETPLACE.MINERALS'})} </a>
                    <a onClick={() => { navigate(`/${lang}/marketplace`); setMarketplaceContentType('sell-wood'); }} className={cn('mobile-menu--item', {'active': isActiveMarketplace('sell-wood')})} >{formatMessage({id:'MARKETPLACE.WOOD'})}</a>

                    <a onClick={() => { navigate(`/${lang}/marketplace`); setMarketplaceContentType('sell-custom_avatars'); }} className={cn('mobile-menu--item', {'active': isActiveMarketplace('sell-custom_avatars')})} >{formatMessage({id:'ASSETS.CUSTOM_AVATARS'})}</a>
                    <a onClick={() => { navigate(`/${lang}/marketplace`); setMarketplaceContentType('sell-custom_models'); }} className={cn('mobile-menu--item', {'active': isActiveMarketplace('sell-custom_models')})} >{formatMessage({id:'ASSETS.CUSTOM_MODELS'})}</a>

                    {isCategory3DVisible && <a onClick={() => { navigate(`/${lang}/marketplace`); setMarketplaceContentType('sell-history'); }} className={cn('mobile-menu--item', {'active': isActiveMarketplace('sell-history')})} >{formatMessage({id:'ASSETSHISTORY.TITLE'})}</a>}
                    <a onClick={() => { navigate(`/${lang}/marketplace/exchangeupgradehistory`); setMarketplaceContentType('sell-exchangeupgradehistory'); }} className={cn('mobile-menu--item', {'active': isActiveMarketplace('sell-exchangeupgradehistory')})} >{formatMessage({id:'MARKETPLACE.EU_HISTORY'})}</a>
                    <a onClick={() => { navigate(`/${lang}/marketplace/bulding-purchase-history`); setMarketplaceContentType('sell-bulding-purchase-history'); }} className={cn('mobile-menu--item', {'active': isActiveMarketplace('sell-bulding-purchase-history')})} >{formatMessage({id:'MARKETPLACE.BP_HISTORY'})}</a>
                </MobileMenuSection>
            </AW937HideElementsForCondition>

            <AW937HideElementsForCondition>
                <MobileMenuSection
                    {...{
                        label: <div>{formatMessage({id:'HEADER.GAMES'})}</div>,
                        activeRoutes: ['game-center', 'gold-rush', 'lumber-yard', 'wow']
                    }}
                >
                    <a onClick={() => { navigate(`/${lang}/game-center`); }} className={'mobile-menu--item' + ([`game-center`].indexOf(postfix) !== -1 ? " active":'')} href={`#`}>{formatMessage({id:'MARKETPLACE.GAME_CENTER'})} </a>
                    <a className={'mobile-menu--item'} href={`https://xracer.game/`} target="_blank"  rel="noreferrer"> XRacer </a>
                    <a onClick={() => { navigate(`/${lang}/gold-rush`); setGoldrushMainstate('default') }} className={'mobile-menu--item' + ([`gold-rush`].indexOf(postfix) !== -1 ? " active":'')} href={`#`}>{formatMessage({id:'HEADER.GOLD_RUSH'})} </a>
                    <a onClick={() => { navigate(`/${lang}/lumber-yard`); setLumberYardMainstate('default') }} className={'mobile-menu--item' + (['lumber-yard'].indexOf(postfix) !== -1 ? " active":'')} href={`#`}>{formatMessage({id:'HEADER.LUMBER_YARD'})}</a>
                </MobileMenuSection>
            </AW937HideElementsForCondition>

            {_2DAccess && <MobileMenuSection
                {...{
                    label: <div>{formatMessage({id:'HEADER.2D'})} </div>,
                    onLabelClick: () => { window.open(`https://2d.${BASE_DOMAIN}/`, "_blank"); },
                }}
            />}

            {member.adnetIsVisible && <MobileMenuSection
                {...{
                    label: formatMessage({id:'HEADER.ADS'}),
                    activeRoutes: ['ads-overview', 'ads-manager', 'ads-schedule', 'ads-aechive', 'ads-credit-history'],
                }}
            >
                <a onClick={() => { navigate(`/${lang}/ads-overview`); }} className={'mobile-menu--item' + ([`ads-overview`].indexOf(postfix) !== -1 ? " active":'')} href={`#`}>{formatMessage({id:'HEADER.ADS_OVERVIEW'})} </a>
                <a onClick={() => { navigate(`/${lang}/ads-manager`); }} className={'mobile-menu--item' + ([`ads-manager`].indexOf(postfix) !== -1 ? " active":'')} href={`#`}>{formatMessage({id:'HEADER.ADS_MANAGER'})} </a>
                <a onClick={() => { navigate(`/${lang}/ads-schedule`); }} className={'mobile-menu--item' + ([`ads-schedule`].indexOf(postfix) !== -1 ? " active":'')} href={`#`}>{formatMessage({id:'HEADER.ADS_SCHEDULE'})} </a>
                <a onClick={() => { navigate(`/${lang}/ads-aechive`); }} className={'mobile-menu--item' + ([`ads-archive`].indexOf(postfix) !== -1 ? " active":'')} href={`#`}>{formatMessage({id:'ADS.LINK_TO_ARCHIVE'})} </a>
                <a onClick={() => { navigate(`/${lang}/ads-credit-history`); }} className={'mobile-menu--item' + ([`ads-credit-history`].indexOf(postfix) !== -1 ? " active":'')} href={`#`}>{formatMessage({id:'HEADER.ADS_CREDIT_HISTORY'})} </a>
                <AW937HideElementsForCondition>
                    <a className={'mobile-menu--item' + ([`adnet-catalog`].indexOf(postfix) !== -1 ? " active":'')} href={ `/${lang}/adnet-catalog`}>{formatMessage({id:'AD_NET_LANDING.CATALOG'})} </a>
                </AW937HideElementsForCondition>
            </MobileMenuSection>}

            <hr/>

            <MobileMenuSection
                {...{
                    label: formatMessage({id:'COMMON.PLOTS'}),
                    onLabelClick: () => { navigate(`/${lang}/my-plots`) },
                    activeRoutes: ['my-plots']
                }}
            />

            <MobileMenuSection
                {...{
                    label: formatMessage({id:'ASSETSHISTORY.TITLE'}),
                    onLabelClick: () => { navigate(`/${lang}/assets-history`)},
                    activeRoutes: ['assets-history']
                }}
            />
            {member.accessTickets && <MobileMenuSection
                {...{
                    label: formatMessage({id:'AW458.USDT_PURCHASE_HISTORY'}),
                    onLabelClick: () => { navigate(`/${lang}/purchase-history`)},
                    activeRoutes: ['purchase-history']
                }}
            />}

            <AW937HideElementsForCondition hide={AW937_IS_HIDDEN}>
                <MobileMenuSection
                    {...{
                        label: formatMessage({id:'AW-885.CITIZEN_PROGRAM'}),
                        onLabelClick: () => { navigate(`/${lang}/points/citizen-program`) },
                        activeRoutes: ['points/citizen-program']
                    }}
                />
                <AW937HideElementsForCondition hide={!founderPlanIsVisible}>
                    <MobileMenuSection
                        {...{
                            label: formatMessage({id:'AW_POINTS.FOUNDERS_PLAN'}),
                            onLabelClick: () => { navigate(`/${lang}/points/founders-plan`) },
                            activeRoutes: ['points/founders-plan']
                        }}
                    />
                </AW937HideElementsForCondition>
            </AW937HideElementsForCondition>
            <AW937HideElementsForCondition>
            <MobileMenuSection
                {...{
                    label: formatMessage({id:'AW_POINTS.REVENUE_SHARING_PROGRAM_MENU'}),
                    onLabelClick: () => { navigate(`/${lang}/points/revenue-sharing-program`) },
                    activeRoutes: ['points/revenue-sharing-program']
                }}
            />
            </AW937HideElementsForCondition>

            {/* <MobileMenuSection
                {...{
                    label: formatMessage({id:'AW_POINTS.POINTS'}),
                    activeRoutes: ['points/founders-plan', 'points/revenue-sharing-program'],
                }}
            >
                <a onClick={() => navigate(`/${lang}/points/founders-plan`)} className={'mobile-menu--item' + ([`points/founders-plan`].indexOf(postfix) !== -1 ? " active":'')} href={`#`}>{formatMessage({id:'AW_POINTS.FOUNDERS_PLAN'})} </a>
                <a onClick={() => navigate(`/${lang}/points/revenue-sharing-program`)} className={'mobile-menu--item' + ([`points/revenue-sharing-program`].indexOf(postfix) !== -1 ? " active":'')} href={`#`}>{formatMessage({id:"AW_POINTS.REVENUE_SHARING_PROGRAM_MENU"})} </a>
            </MobileMenuSection> */}
            <hr/>

            <MobileMenuSection
                {...{
                    label: formatMessage({id:'HEADER.SUPPORT'}),
                    activeRoutes: ['announcements', 'events', 'tutorials', 'support/all', 'support/open', 'support/in-progress', 'support/close'],
                }}
            >
                <a onClick={() => navigate(`/${lang}/announcements`)} className={'mobile-menu--item' + ([`announcements`].indexOf(postfix) !== -1 ? " active":'')} href={`#`}>{formatMessage({id:'HEADER.ANNOUNCEMENTS'})} </a>
                <a onClick={() => navigate(`/${lang}/events`)} className={'mobile-menu--item' + ([`events`].indexOf(postfix) !== -1 ? " active":'')} href={`#`}>{formatMessage({id:'HEADER.EVENTS'})} </a>
                <a onClick={() => navigate(`/${lang}/support`)} className={'mobile-menu--item' + (postfix.match(/^support\/(all|open|in-progress|closed)/) ? " active":'')} href={`#`}>{formatMessage({id:'HEADER.SUPPORT'})} </a>
                <a onClick={() => navigate(`/${lang}/tutorials`)} className={'mobile-menu--item' + ([`tutorials`].indexOf(postfix) !== -1 ? " active":'')} href={`#`}>{formatMessage({id:'HEADER.TUTORIALS'})} </a>
            </MobileMenuSection>

            <hr/>
            <MobileMenuSection
                {...{
                    sectionClass: '',
                    label: menuLang.find(({href}) => href === `/${lang}`).label,
                }}
            >
                {
                    menuLang.filter(({href}) => href !== `/${lang}`)
                        .map(({href, label}, indx) => {
                            const url = postfix.match(/^\//) ? href : `${href}/${postfix}`;
                            return <a key={indx} onClick={() => window.location = url} className={'mobile-menu--item' + ([`announcements`].indexOf(postfix) !== -1 ? " active":'')} href={`#`}>{label}</a>
                        })
                }
            </MobileMenuSection>

            <MobileMenuSection
                {...{
                    sectionClass: '',
                    label: formatMessage({id:'LINK.DOWNLOADS'}),
                    onLabelClick: () => { navigate(`/${lang}/downloads`) },
                    activeRoutes: ['downloads']
                }}
            />

            <MobileMenuSection
                {...{
                    sectionClass: '',
                    label: formatMessage({id:'COMMON.LOGOUT'}),
                    onLabelClick: handleShow,
                }}
            />
        </div>}
    </>

    const Desktop =  <Navbar className="main-header w-100" bg="white" variant="light">
        <Container fluid={true}>
            <Navbar.Brand href={[`/${lang}`, `/${lang}/`, ''].indexOf(postfix) !== -1 ? null: `/${lang}`} className={"py-0"}>
                <img alt="" src={logo} style={{height: "32px"}}></img>
                <h1>{formatMessage({id:"HEADER.VLANDS"})}</h1>
            </Navbar.Brand>
            <Navbar.Collapse>
                <Nav className={"me-auto"}>
                    <Nav.Link  className={"px-3 py-2"} href={`/${lang}`} onClick={(e) => { e.preventDefault(); navigate(`/${lang}`) }} active={[`/${lang}`, `/${lang}/`, ''].indexOf(postfix) !== -1}>{formatMessage({id:'HEADER.MAP'})}</Nav.Link>
                    {
                        isShortMenu
                            ? <HeaderShortMenu yesNetCallback={yesNetCallback}/>
                            : <HeaderFullMenu yesNetCallback={yesNetCallback}/>
                    }
                </Nav>

                <Nav className={"align-items-center header--right-nav"}>


                    <AW937HideElementsForCondition>
                        <>
                            <Dropdown autoClose="outside" className="d-flex header--balances-dropdown" ref={awRef} onClick={() => {
                                if (promt.complitedPromt.skipAll) {
                                    complitePromt('aw');
                                    // setShowSysWalletToAbey(true);
                                }
                            }}>
                                <BalanceSubmenu
                                    {...{member, awRef, postfix, complitePromt, setShowSysWalletToAbey, }}
                                />
                            </Dropdown>

                        <NavDropdown.Divider className={"mx-0"} />

                            <Nav.Item onClick={() => {
                                if (member.sysWalletAddress  && promt.complitedPromt.skipAll) {
                                    complitePromt('sys');
                                    setShowSysWallet(true);
                                }
                            }} ref={sysRef}>
                                <BalanceItem type="sysWalletBalance"  icon="faw-wallet " />
                            </Nav.Item>
                        </>
                    </AW937HideElementsForCondition>

                    {member.accessTickets && <>
                        <Nav.Item ref={ticketRef}>
                            <BalanceItem type="tickets" hideCross={true}  icon="faw-stickers "/>
                        </Nav.Item>
                    </>}
                    <div className="d-flex gap-1 align-items-center">
                    <NavDropdown.Divider className={"mx-0"} />

                    <Dropdown autoClose="outside" className="d-flex">
                        <Dropdown.Toggle
                            variant={"header"}
                            className={[
                                'd-flex justify-content-between align-items-center',
                                ['my-plots', 'experience-tickets', 'linked-wallet'].indexOf(postfix) !== -1 ? "active":''
                            ].join(' ')}>
                            <div>{formatMessage({id:'COMMON.ID'})}: {member.id}</div>
                        </Dropdown.Toggle>
                        <Dropdown.Menu >
                            <>
                                <AW937HideElementsForCondition>
                                    <LinkedWalletMenuItem />
                                </AW937HideElementsForCondition>
                                <NavDropdown.Divider />
                            </>
                            <Dropdown.Item
                                onClick={(e) => { e.preventDefault(); navigate(`/${lang}/my-plots`) }}
                                className={[`my-plots`].indexOf(postfix) !== -1 ? "active":''}
                                href={`/${lang}/my-plots`}>{formatMessage({id:'COMMON.PLOTS'})} </Dropdown.Item>
                            <Dropdown.Item
                                onClick={(e) => { e.preventDefault(); navigate(`/${lang}/assets-history`) }}
                                className={[`assets-history`].indexOf(postfix) !== -1 ? "active":''}
                                href={`/${lang}/assets-history`}>{formatMessage({id:'ASSETSHISTORY.TITLE'})} </Dropdown.Item>
                            {member.accessTickets && <Dropdown.Item
                                onClick={(e) => { e.preventDefault(); navigate(`/${lang}/purchase-history`) }}
                                className={[`purchase-history`].indexOf(postfix) !== -1 ? "active":''}
                                href={`/${lang}/purchase-history`}>{formatMessage({id:'AW458.USDT_PURCHASE_HISTORY'})} </Dropdown.Item>}
                            <NavDropdown.Divider />
                            <Dropdown.Item
                                onClick={(e) => { e.preventDefault(); navigate(`/${lang}/experience-tickets`) }}
                                className={[`experience-tickets`].indexOf(postfix) !== -1 ? "active":''}
                                href={`/${lang}/experience-tickets`}>
                                <div className="d-flex justify-content-between">
                                    <div>{formatMessage({id:'HEADER.PLOT_TICKETS'})}: </div>
                                    <div className={"text-orange ps-3"}>{ticketFormat(member.tickets)}</div>
                                </div>
                            </Dropdown.Item>
                            <AW937HideElementsForCondition hide={!memberHasPlots}>
                                <Dropdown.Item
                                    onClick={(e) => { e.preventDefault(); }}
                                >
                                    <div className="d-flex justify-content-between">
                                        <div>AP: </div>
                                        <div className={"text-orange ps-3"}>{ticketFormat(member.points)}</div>
                                    </div>
                                </Dropdown.Item>
                            </AW937HideElementsForCondition>
                            <AW937HideElementsForCondition hide={AW937_IS_HIDDEN}>
                                <Dropdown.Item
                                    onClick={(e) => { e.preventDefault(); navigate(`/${lang}/points/citizen-program`) }}
                                    className={[`points/citizen-program`].indexOf(postfix) !== -1 ? "active":''}
                                    href={`/${lang}/points/citizen-program`}>{formatMessage({id:'AW-885.CITIZEN_PROGRAM'})} </Dropdown.Item>
                                <AW937HideElementsForCondition hide={!founderPlanIsVisible}>
                                    <Dropdown.Item
                                        onClick={(e) => { e.preventDefault(); navigate(`/${lang}/points/founders-plan`) }}
                                        className={[`points/founders-plan`].indexOf(postfix) !== -1 ? "active":''}
                                        href={`/${lang}/points/founders-plan`}>{formatMessage({id:'AW_POINTS.FOUNDERS_PLAN'})} </Dropdown.Item>
                                </AW937HideElementsForCondition>
                            </AW937HideElementsForCondition>

                            <AW937HideElementsForCondition>
                                <Dropdown.Item
                                    onClick={(e) => { e.preventDefault(); navigate(`/${lang}/points/revenue-sharing-program`) }}
                                    className={[`points/revenue-sharing-program`].indexOf(postfix) !== -1 ? "active":''}
                                    href={`/${lang}/points/revenue-sharing-program`}>{formatMessage({id:"AW_POINTS.REVENUE_SHARING_PROGRAM_MENU"})} </Dropdown.Item>
                            </AW937HideElementsForCondition>
                            <NavDropdown.Divider />

                            <Dropdown.Item
                                onClick={handleShow}
                                // className={['lumber-yard'].indexOf(postfix) !== -1 ? "active":''}
                                href={`#`}>{formatMessage({id:'HEADER.LOG_OUT'})} </Dropdown.Item>
                        </Dropdown.Menu>
                    </Dropdown>

                    <NavDropdown.Divider className={"mx-0"} />

                    <LanguageMenu variant={"header"} />

                    </div>
                </Nav>
            </Navbar.Collapse>

        </Container>
    </Navbar>

    return (<>
        {mobile ? Mobile : Desktop}
        <ModalConfirm
            show={show}
            handleClose={handleClose}
            handleConfirm={onClickLogout}
        />
        <ModalSysWallet
            show={showSysWallet}
            onClose={()=> {
                mobile
                    ? activatePromt('mobileAw')
                    : activatePromt('aw');
                setShowSysWallet(false);
            }}
        />
        <ModalSysWalletToAbey
            show={showSysWalletToAbey}
            onClose={()=> {
                member.accessTickets && (mobile
                    ? activatePromt('mobileTicket')
                    : activatePromt('ticket'));
                setShowSysWalletToAbey(false)
            }}
        />
        <ModalTickets
            show={showTickets}
            onSkip={() => {
                setShowTickets(false);
            }}
            onClose={()=> {
                setShowTickets(false);
                setMarketplaceContentType('buy-tickets');
                navigate(`/${lang}/marketplace`);
                setShowCompltePromts(promt.complitedPromt.mobileTicket || promt.complitedPromt.ticket);
            }}
        />
        <ModalComplitePromt
            show={showCompltePromts}
            onClose={()=> {setShowCompltePromts(false);}}
        />
        <MobileModalAWBalances
            show={showMobileAWBalancesModal}
            onClose={() => setShowMobileAWBalancesModal(false)}
            {...{setShowSysWalletToAbey}}
        />
    </>);
}

export default connect(
    (state) => ({
        member: state.member,
        mobile: state.common.mobile,
        isProd: state.common.isProd,
        isShortMenu: state.common.isShortMenu,
        promt: state.promt,
        myMinerals: state.member.minerals,
        myWood: state.member.wood,
        memberHasPlots: state.member.memberHasPlots,
        _2DAccess: state.member._2DAccess,
        founderPlanIsVisible: state.member.founderPlanIsVisible,
        isAllowedVB: state.member.isAllowedVB,
    }),
    (dispatch) => ({
        setGoldrushMainstate: (step) => {
            dispatch(setGoldrushMainstate(step));
        },
        setLumberYardMainstate: (step) => {
            dispatch(setLyState(step));
        },
        setMarketplaceDefault: (state) => {
            dispatch(setMarketplaceDefault(state));
        },
        addPromtElement: (state) => {
            dispatch(addPromtElement(state));
        },
        activatePromt: (state) => {
            dispatch(activatePromt(state));
        },
        complitePromt: (state) => {
            dispatch(complitePromt(state));
        },
        setMarketplaceContentType: (showModalAbeyWallet) => {
            dispatch(setMarketplaceContentType(showModalAbeyWallet));
        },
    }),
)(injectIntl(Header));
