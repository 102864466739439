import {connect} from "react-redux";
import {useEffect, useMemo} from "react";
import {useNavigate, useParams} from "react-router-dom";
import loginService from "../api/login";
import {setExpiredAt, setAccessToken} from "../actions";
import { getExpiredAt, localStorageClearNot } from "../services/utils";

const AuthChecker = ({children, logged, expiredAt, setExpiredAt, setAccessToken, member}) => {
    let navigate = useNavigate();
    const { lang } = useParams();
    const postfix = window.location.pathname.replace(`/${lang}`, '');
    const isStaff = window.location.pathname.includes('/staff/');

    const requiredAuth = useMemo(() => ([
        '/my-plots', 
        '/plot-tickets', 
        'experience-tickets',
        '/marketplace', 
        '/gold-rush', 
        '/lumber-yard',
        '/announcements',
        '/tutorials',
        '/support',
        '/support/all',
        '/support/create', 
        '/wow',
        '/game-center',
        '/game-center/'
    ]), []);
    const requiredNotAuth = useMemo(() => (['/login']), []);

    useEffect(() => {
        if (isStaff) {
            navigate("/staff");
            return;
        }

        !logged && (() => {
            if (requiredAuth.indexOf(postfix) !== -1) {
                localStorage.setItem('afterLogin', postfix);
                localStorageClearNot(['afterLogin', 'promtState']);
                navigate(`/${lang}/login`); 
            }
        })()

        logged && (() => {
            if (requiredNotAuth.indexOf(postfix) !== -1) {
                navigate(`/${lang}`);
            }
        })()

    }, [lang, logged, navigate, postfix, requiredAuth, requiredNotAuth]);

    useEffect(() => {
        if (isStaff) {
            navigate("/staff");
            return;
        }

        return logged && (() => {

            const limit = parseInt(process.env.REACT_APP_UPDATE_TOKEN_BEFORE_EXPIRATION*60*1000);
            let diff = expiredAt?.getTime()-(new Date()).getTime(),
                updateIn = diff - limit;

            const id = setTimeout(function recursion() {
                loginService.refresh()
                    .then(response => response.json())
                    .then(data => {
                        const {
                            accessToken,
                            expiresIn
                        } = data;
                        const newExpiredAt = getExpiredAt(expiresIn);

                        setExpiredAt(newExpiredAt);
                        setAccessToken(accessToken);

                        diff = newExpiredAt.getTime()-(new Date()).getTime();
                        updateIn = diff - limit;

                        loginService.api.reinit();
                        setTimeout(recursion, updateIn);
                    });
            }, updateIn);

            return () => clearTimeout(id);
        })();
    }, [expiredAt, logged, setAccessToken, setExpiredAt]);

    return children;
};

export default connect(
    (state) => ({
        member: state.member,
        logged: !!state.member.id,
        expiredAt: state.login.expiredAt
    }),
    (dispatch) => ({
        setExpiredAt: (expiredAt) => {
            dispatch(setExpiredAt(expiredAt));
        },
        setAccessToken: (accessToken) => {
            dispatch(setAccessToken(accessToken));
        },
    })
)(AuthChecker);
