import {Navigate} from "react-router-dom";
import {connect} from "react-redux";

const Empty = ({member}) => {
    if (!member || member?.id <= 0) {
        window.location.href = "/staff/login";
        return;
    }

    if (member?.roles?.find((element) => element === "3d-models")) {
        return <Navigate to={`/staff/queue_avatars`} />
    }

    return <Navigate to={`/staff/announcements`} />
};

const EmptyComponent = connect(
    (state) => ({
        member: state.member
    }),
)(Empty);

export default EmptyComponent;
