function check(envString = '') {
  return (memberId) => {
    return envString === 'all' || envString.split(',')
      .map(item => parseInt(item))
      .indexOf(memberId) !== -1;
  }
}

export const checkCategory3DVisibility = check(process.env?.REACT_APP_MARKETPLACE_3D_VISIBLE_FOR);
export const check3DAssetsVisibility = check(process.env?.REACT_APP_MARKETPLACE_3D_ASSETS_VISIBLE_FOR);
